import React from "react";
import Banner from "../components/banner/banner";
import PartnerSection from "../components/PartnerSection/partner";
import Howtogrow from "../components/how-to-grow-section/Howtogrow";
import AboutSection from "../components/aboutsection/AboutSection";
import BenefitsSection from "../components/BenefitsSection/BenefitsSection";
import UseCases from "../components/UseCasesSection/UseCases";
import IntegrationSection from "../components/IntegrationSection/integration";
import FAQ from "../components/FAQSection/FAQ";
import SecondBanner from "../components/SecondBannerSection/SecondBanner";
import FooterSection from "../components/FooterSection/footer";

export default function AutonomousOps() {
  return (
    <div>
      <Banner />
      <PartnerSection />
      <Howtogrow />
      <AboutSection />
      <BenefitsSection />
      <UseCases />
      <IntegrationSection />
      <FAQ />
      <SecondBanner />
      <FooterSection />
    </div>
  );
}
