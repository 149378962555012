import React from "react";
import "./BenefitsSection.css";
import benefitImage1 from "../../assets/BenefitsSection/benefit-img1.svg";
import benefitImage2 from "../../assets/BenefitsSection/benefit-img2.svg";
import benefitImage3 from "../../assets/BenefitsSection/benefit-img3.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

const BenefitsSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`benefits-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="two"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <p>Benefits</p>
            </div>
            <h2>
              Streamline Operations Boost Efficiency, Accuracy, and Scalability
            </h2>
            <p>
              Automates tasks, optimizes workflows, and reduces costs while
              enhancing accuracy
            </p>
          </div>
          <div className="benefits-cards-wrapper row">
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 benefits-card">
              <div className="benefits-card-inner first-card">
                <h3>Enhanced Operational Efficiency</h3>
                <p>
                  Automates repetitive tasks and optimizes workflows, leading to
                  faster completion and increased overall productivity. This
                  allows employees to focus on more strategic and high-value
                  activities.
                </p>
                <img src={benefitImage1} alt="Benefits card" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 benefits-card">
              <div className="benefits-card-inner second-card">
                <img src={benefitImage2} alt="crown icon" />
                <h3>Improved Accuracy and Consistency</h3>
                <p>
                  Reduces the risk of human error by standardizing processes,
                  ensuring reliable and consistent performance across
                  operations.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 benefits-card">
              <div className="benefits-card-inner third-card">
                <img src={benefitImage3} alt="money icon" />
                <h3>Cost Reduction</h3>
                <p>
                  Cuts operational costs by decreasing the need for extensive
                  staffing and training while optimizing the use of human and
                  technical resources.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 benefits-card">
              <div className="benefits-card-inner fourth-card">
                <h3>Increased Agility and Scalability</h3>
                <p>
                  Facilitates rapid adaptation to changing market conditions and
                  scalable growth, allowing organizations to respond effectively
                  to evolving demands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
