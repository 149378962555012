const local = {
  info: {
    REACT_APP_WEBSITE_URL: "https://autonomousops-ai.lab.neuralcompany.team/",
  },
  bookdemo: {
    Link: "https://autonomousops-bookdemo.lab.neuralcompany.team/autonomousops",
  },
};

const dev = {
  auth: {
    REACT_APP_AUTH_URL: "https://akiraai-auth.neuralcompany.team/api/auth",
  },
  app: {
    WORKSPACE_DOMAIN_NAME: ".neuralcompany.team",
  },
  info: {
    REACT_APP_WEBSITE_URL: "https://autonomousops-ai.lab.neuralcompany.team/",
  },
  bookdemo: {
    Link: "https://autonomousops-bookdemo.lab.neuralcompany.team/autonomousops",
  },
};

const prod = {
  auth: {
    REACT_APP_AUTH_URL: "https://app.akira.ai/api/auth",
  },
  app: {
    WORKSPACE_DOMAIN_NAME: ".akira.ai",
  },
  info: {
    REACT_APP_WEBSITE_URL: "https://autonomousops.ai/",
  },
  bookdemo: {
    Link: "https://demo.akira.ai/autonomousops/",
  },
};

const config =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "development"
    ? dev
    : // : process.env.REACT_APP_STAGE === "uat"
      // ? uat
      local;
// eslint-disable-next-line
export default {
  ...config,
};
