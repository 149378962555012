import React from "react";
import "./howtogrow.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
export default function Howtogrow() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`how-to-grow-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <p>ROI</p>
            </div>
            <h2>How AutonomousOps AI Fuels Enterprise Growth</h2>
            <p>
              Boost efficiency, cut costs, and resolve issues faster with
              automation.
            </p>
          </div>
          <div className="how-to-grow-content-wrapper">
            <div className="roi-card efficiency">
              <span>45%</span>
              <h3>Efficiency</h3>
            </div>
            <div className="roi-card reduction">
              <span>77%</span>
              <h3>Cost Reduction</h3>
            </div>
            <div className="roi-card timely-resolution">
              <span>90%</span>
              <h3>Timely Resolution</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
