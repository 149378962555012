import React, { useState } from "react";
import "./FAQ.css";
import faqShow from "../../assets/FAQ/faq-show.svg";
import faqHide from "../../assets/FAQ/faq-hide.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: "How do AI agents in AutonomousOps AI improve operations?",
      answer:
        "AutonomousOps AI agents automate routine tasks, optimize workflows, and provide real-time insights, leading to faster response times and greater efficiency.",
    },
    {
      question: "What kind of data does AutonomousOps AI need to operate?",
      answer:
        "Our platform uses operational data from your IT, security, and customer support systems to streamline and automate processes.",
    },
    {
      question:
        "Can AutonomousOps AI be customized for different operational processes?",
      answer:
        "Yes, AutonomousOps AI is highly customizable and can be tailored to suit the specific needs of your business processes across various departments.",
    },
    {
      question: "How quickly can I set up AutonomousOps AI?",
      answer:
        "The setup process is quick and seamless, allowing you to integrate with existing systems and start automating operations within days.",
    },
    {
      question: "Does AutonomousOps AI require technical expertise to use?",
      answer:
        "No, AutonomousOps AI is designed to be user-friendly, requiring minimal technical expertise to operate. Our support team is available to assist with any setup or troubleshooting needs.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`first-accordion ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="four"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <p>FAQ</p>
            </div>
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className="accordion">
            {faqData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <button
                  id={`accordion-button-${index + 1}`}
                  aria-expanded={activeIndex === index ? "true" : "false"}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">{item.question}</span>
                  <img
                    src={faqShow}
                    alt="Show Button"
                    className="accordion-show"
                    style={{
                      display: activeIndex === index ? "none" : "block",
                    }}
                  />
                  <img
                    src={faqHide}
                    alt="Hide Button"
                    className="accordion-hide"
                    style={{
                      display: activeIndex === index ? "block" : "none",
                    }}
                  />
                </button>
                {activeIndex === index && (
                  <div className="accordion-content">
                    <p>{item.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
